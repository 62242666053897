function viaCep(cep, callback) {
  const headers = {
    'Content-type': 'application/json',
    Accept: 'application/json',
  };

  fetch(`https://viacep.com.br/ws/${cep}/json/`, { headers })
    .then((response) => response.json())
    .then((data) => callback(data));
}

function testCep(cep, callback) {
  setTimeout(() => {
    callback({
      bairro: 'Centro',
      localidade: 'Curitiba',
      logradouro: 'Dr Mestre Padre Top',
      numero: '',
      complemento: '',
      uf: 'PR',
    });
  }, 500);
}

function getAddress(cep, callback) {
  const unmaskCep = cep.replace(/[^\d]/g, '');
  // eslint-disable-next-line no-undef
  if (window.location.hostname === 'localhost') {
    testCep(unmaskCep, callback);
  } else {
    viaCep(cep, callback);
  }
}

export default getAddress;
