import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB_sSLVnV20uIPPJGFIHt2jCVTpMO_n4Jw',
  authDomain: 'fgtsuper-prod.firebaseapp.com',
  databaseURL: 'https://fgtsuper-prod.firebaseio.com',
  storageBucket: 'fgtsuper-prod.appspot.com',
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

// eslint-disable-next-line import/prefer-default-export
export { storage };
