import React from 'react';

function TransferPage() {
  return (
    <>
      e ai galeraa
    </>
  );
}

export default TransferPage;
