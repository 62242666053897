import React from 'react';

import { IMaskMixin } from 'react-imask';
import { FormControl, TextField } from '@mui/material';

/* eslint-disable react/jsx-props-no-spreading */
const InputMask = IMaskMixin(({ inputRef, ...props }) => (
  <FormControl fullWidth>
    <TextField
      {...props}
      inputRef={inputRef}
    />
  </FormControl>
));

export default InputMask;

/* eslint-enable react/jsx-props-no-spreading */
