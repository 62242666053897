import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Typography, LinearProgress, Grid, colors,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import doc from '../../assets/images/doc.svg';

const useStyles = makeStyles(() => ({
  card: {
    minHeight: 136,
  },
  image: {
    height: '100%',
    width: '100%',
    maxHeight: 170,
    objectFit: 'contain',
  },
  text: {
    lineHeight: 3,
    textAlign: 'center',
    fontSize: 16,
    paddingTop: 4,
  },
  subtitle: {
    display: 'block',
    textAlign: 'center',
    fontSize: 14,
  },
  title: {
    color: '#666',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bolder',
    paddingBottom: 5,
  },
  placeholder: {
    maxHeight: 140,
  },
  error: {
    color: colors.red[600],
  },
}));

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const errorStyle = {
  borderColor: colors.red[600],
};

const maxSize = 10485761;

/* eslint-disable no-nested-ternary, react/prop-types, react/jsx-props-no-spreading */
function Dropzone(props) {
  const {
    handleDrop, loading, progress, preview, error, title,
  } = props;

  const classes = useStyles();

  const [files, setFiles] = useState([]);

  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length) {
        handleDrop(acceptedFiles);
        setFiles(acceptedFiles);
      }
    },
    [handleDrop],
  );

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
    minSize: 0,
    maxSize,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    ...(error ? errorStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
    error,
  ]);

  const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0]?.size > maxSize;

  return (
    <>
      <div {...getRootProps({ style })}>
        <Typography variant="body2" className={classes.title}>
          { title }
        </Typography>
        <input {...getInputProps()} />
        <div className={classes.card}>
          {loading ? (
            <>
              <Typography variant="subtitle1">Carregando imagem...</Typography>
              <LinearProgress
                variant="determinate"
                value={progress}
              />
            </>
          ) : preview ? (
            <img src={preview} alt={title} className={classes.image} width={100} />
          ) : (
            <Typography variant="subtitle2">
              <div>
                {isFileTooLarge ? (
                  <div className="text-danger mt-2">
                    Tamanho máximo aceito é 10mb
                  </div>
                ) : !isDragReject ? (
                  <Grid container>
                    <Grid item xs={12} sm={5} align="center">
                      <img
                        src={doc}
                        alt="Documento"
                        className={classes.placeholder}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Typography variant="body1" className={classes.text}>
                        Clique aqui para selecionar o arquivo
                      </Typography>
                      <Typography variant="body2" className={classes.subtitle}>
                        O arquivo deve ter até 10mb e ser no formato png ou jpg.
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  'Tipo de arquivo não aceito!'
                )}
              </div>
            </Typography>
          )}
        </div>
      </div>
      {error ? (
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.error}>
            Selecione um arquivo
          </Typography>
        </Grid>
      ) : null}
    </>
  );
}

export default Dropzone;

/* eslint-enable no-nested-ternary, react/prop-types, react/jsx-props-no-spreading */
