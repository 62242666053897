import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import {
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import InputMask from '../common/InputMask';

import { storage } from '../../firebase';
import { validateImage } from '../../utils/image';
import Dropzone from '../common/Dropzone';
import getAddress from '../../api/CepService';

/* eslint-disable react/prop-types, react/function-component-definition */
export default function AddressForm(props) {
  const {
    register,
    control,
    errors,
    setValue,
    previewAddressImage,
    setPreviewAddressImage,
    handleChangeImage,
    setError,
  } = props;

  const env = 'production';
  const [files, setFiles] = useState({});
  const [loadingAddressImage, setLoadingAddressImage] = useState(false);
  const [progressAddressImage, setProgressAddressImage] = useState(0);

  register('addressImage', { required: true });

  const resetValues = () => {
    setValue('neighborhood', '');
    setValue('city', '');
    setValue('state', '');
    setValue('street', '');
    setValue('state', '');
  };

  register('postalCode', {
    onChange: (e) => {
      console.log('Aeee');
      const cep = e.target.value;
      if (cep.length === 9) {
        resetValues();

        getAddress(cep, (address) => {
          setValue('neighborhood', address.bairro);
          setValue('city', address.localidade);
          setValue('street', address.logradouro);
          setValue('state', address.uf);
        });
      }
    },
  });

  const saveImage = (name, fileUrl) => {
    setLoadingAddressImage(false);
    handleChangeImage('addressImage', fileUrl);
  };

  const imageCheckCallbackError = (name) => {
    setLoadingAddressImage(false);
    setError(name, {
      type: 'manual',
      message: 'Imagem enviada inválida',
    });
  };

  const uploadImage = (name, file) => {
    setLoadingAddressImage(true);

    const upload = () => {
      const fileName = `${uuid()}-${file.name}`;
      const imageRef = ref(storage, `images/${env}/${name}/${fileName}`);

      const uploadTask = uploadBytesResumable(imageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const uploadProgress = (snapshot.bytesTransferred / (snapshot.totalBytes || 1)) * 100;
          setProgressAddressImage(uploadProgress);
        },
        () => {
          setProgressAddressImage(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            saveImage(name, downloadURL);
          });
        },
      );
    };

    validateImage(name, file, upload, imageCheckCallbackError);
  };

  const onDrop = (name, acceptedFiles) => {
    let file = acceptedFiles[0];
    file = Object.assign(file, { preview: URL.createObjectURL(file) });
    const newFiles = { ...files, [name]: file };

    setProgressAddressImage(0);
    setPreviewAddressImage(URL.createObjectURL(file));
    setFiles(newFiles);
    uploadImage(name, file);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Endereço
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Agora, precisamos do seu endereço residencial.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          name="postalCode"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask="00000-000"
              value={value}
              onChange={onChange}
              required
              label="CEP"
              variant="outlined"
              error={!!errors.postalCode}
              helperText={errors.postalCode && 'CEP é obrigatório'}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Controller
          name="street"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Rua"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.street}
              helperText={errors.street && 'Rua é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          name="number"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Número"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.number}
              helperText={errors.number && 'Número é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          name="neighborhood"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Bairro"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.neighborhood}
              helperText={errors.neighborhood && 'Bairro é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          name="complement"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              label="Complemento"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.complement}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="city"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Cidade"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.city}
              helperText={errors.city && 'Cidade é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="state"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Estado"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.state}
              helperText={errors.state && 'Estado é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Comprovante de Residência*
        </Typography>
        <Typography variant="body2" gutterBottom>
          O comprovante precisa ser em seu nome ou em nome dos seus pais.
          Deve ter sido emitido recentemente.
        </Typography>

        <Dropzone
          title="Comprovante de residência"
          loading={loadingAddressImage}
          progress={progressAddressImage}
          preview={previewAddressImage}
          handleDrop={(acceptedFiles) => onDrop('addressImage', acceptedFiles)}
          error={errors.addressImage}
        />
      </Grid>
    </Grid>
  );
}
/* eslint-enable react/prop-types, react/function-component-definition */
