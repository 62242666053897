import * as React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  Grid, InputLabel, Link, MenuItem, Select,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import PersonalDataForm from '../main/PersonalDataForm';
import AddressForm from '../main/AddressForm';
import BankForm from '../main/BankForm';
import { bankByCode } from '../../utils/banks';
import dateConverter from '../../utils/date';

const useStyles = makeStyles(() => ({
  modal: {
    background: '#f0f0f2',
    // border: '8px solid #29368EFF',
    padding: '15px',
  },
  link: {
    padding: '8px',
    marginRight: '35px!important',
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'inline-block',
    decoration: 'none',
  },
  content: {
    height: '100hv',
  },
  footer: {
    position: 'fixed',
    width: 'calc(100% - 45px)',
    background: '#fff',
    bottom: '15px',
    padding: '25px 8px!important',
    boxShadow: '2px -2px 5px rgba(0,0,0,.1)',
    zIndex: '999',
  },
  lastBtn: {
    marginRight: '20px!important',
  },
}));

/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
function EditModal(props) {
  const classes = useStyles();
  const {
    updateCustomer, setEdit, isEditing, customer,
  } = props;

  const selfieImage = customer.selfie_image;
  const documentImage = customer.document_image;
  const documentImageBack = customer.document_image_back;
  const addressImage = customer.address_image;

  const {
    control, trigger, register, setValue, reset, getValues, formState: { errors }, clearErrors,
  } = useForm();

  const installmentsRange = [1, 2, 3, 4, 5];
  const [previewSelfie, setPreviewSelfie] = useState(customer.selfie_image);
  const [previewDocument, setPreviewDocument] = useState(customer.document_image);
  const [previewDocumentBack, setPreviewDocumentBack] = useState(customer.document_image_back);
  const [previewAddressImage, setPreviewAddressImage] = useState(customer.address_image);
  const [error, setError] = useState(null);

  useState(() => {
    const payload = {
      cpf: customer.cpf,
      fullName: customer.name,
      birthDate: dateConverter(customer.birth_date),
      phone: customer.phone,
      motherName: customer.mother_name,
      job: customer.profession,
      agency: customer.bank_branch_number,
      bank: `${customer.bank_code}`,
      postalCode: customer.postal_code,
      accountNumber: customer.bank_account_number,
      accountNumberDigit: customer.bank_account_digit,
      accountType: customer.bank_savings_account ? 'SAVINGS_ACCOUNT' : 'CHECKING_ACCOUNT',
      documentImage: customer.document_image,
      documentImageBack: customer.document_image_back,
      selfieImage: customer.selfie_image,
      addressImage: customer.address_image,
      city: customer.city,
      complement: customer.complement,
      neighborhood: customer.neighborhood,
      number: customer.number,
      state: customer.state,
      street: customer.street,
      email: customer.email,
      desired_installments: customer.desired_installments,
    };
    reset(payload);
  }, [customer]);

  register('documentImage', { required: true });
  register('documentImageBack', { required: false });
  register('selfieImage', { required: true });

  const handleChangeImage = (name, value) => {
    setValue(name, value);
    clearErrors(name);
  };

  const handleClose = () => {
    clearErrors();
    setEdit(false);
  };

  const handleSave = async () => {
    const valid = await trigger();
    console.log('getValues()', getValues());
    console.log('bankByCode', bankByCode(`${getValues().bank}`));
    if (valid) {
      updateCustomer(
        {
          ...getValues(),
          bank: bankByCode(`${getValues().bank}`),
        },
      );
      setEdit(false);
    }
  };

  return (
    <div>
      <Dialog
        className={classes.modal}
        open={isEditing}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
        maxWidth="xl"
        fullScreen
      >
        <Grid container spacing={4} padding={5} className={classes.content}>
          <Grid item xs={12}>
            { selfieImage && (<Link className={classes.link} href={selfieImage} target="_blank" rel="noreferrer"> Selfie </Link>) }

            { documentImage && (<Link className={classes.link} href={documentImage} target="_blank" rel="noreferrer"> Documento </Link>) }

            { documentImageBack && (<Link className={classes.link} href={documentImageBack} target="_blank" rel="noreferrer"> Verso </Link>) }

            { addressImage && (<Link className={classes.link} href={addressImage} target="_blank" rel="noreferrer"> Endereço </Link>) }
            <hr />
          </Grid>
          <Grid item xs={12}>
            <FormControl error={!!errors.desired_installments}>
              <InputLabel id="desiredInstallmentsLabel">Parcelas</InputLabel>
              <Controller
                name="desired_installments"
                control={control}
                defaultValue="5"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    labelId="desiredInstallmentsLabel"
                    label="Parcelas"
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                  >
                    {
                      installmentsRange.map((i) => (<MenuItem key={i} value={i}>{`${i} Parcelas`}</MenuItem>))
                    }
                  </Select>
                )}
              />
              {errors.installments && (
                <FormHelperText>Parcelas obrigatório</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <PersonalDataForm
              previewSelfie={previewSelfie}
              setPreviewSelfie={setPreviewSelfie}
              previewDocument={previewDocument}
              setPreviewDocument={setPreviewDocument}
              previewDocumentBack={previewDocumentBack}
              setPreviewDocumentBack={setPreviewDocumentBack}
              setError={setError}
              handleChangeImage={handleChangeImage}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={4}>
            <AddressForm
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              previewAddressImage={previewAddressImage}
              setPreviewAddressImage={setPreviewAddressImage}
              handleChangeImage={handleChangeImage}
            />
          </Grid>

          <Grid item xs={4}>
            <BankForm
              control={control}
              errors={errors}
              register={register}
            />
          </Grid>
        </Grid>
        <DialogActions className={classes.footer}>
          {error}
          <Button color="gray" variant="contained" onClick={handleClose}>Fechar</Button>
          <Button className={classes.lastBtn} color="secondary" variant="contained" onClick={handleSave} autoFocus>
            Salvar
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export const Customer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  selfie_image: PropTypes.string.isRequired,
  document_image: PropTypes.string.isRequired,
  document_image_back: PropTypes.string.isRequired,
  address_image: PropTypes.string.isRequired,
});

EditModal.propTypes = {
  setEdit: PropTypes.func.isRequired,
  customer: PropTypes.objectOf(Customer).isRequired,
  updateCustomer: PropTypes.func.isRequired,
};

export default EditModal;
/* eslint-enabled react/prop-types, react/jsx-props-no-spreading */
