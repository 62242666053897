import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BalanceReport, { Report } from './BalanceReport';
import BalanceAnniversary, { Anniversary } from './BalanceAnniversary';

const useStyles = makeStyles(() => ({
  container: {
    padding: '15px',
    margin: '5px',
    fontSize: '18px',
    fontWeight: 'bolder',
    border: '1px solid #ccc',
    borderRadius: 3,
  },
  label: {
    margin: '05px 0px',
    fontSize: '18px',
    fontWeight: 'bolder',
  },
}));

function BalanceResultContainer(props) {
  const { result, error, loading } = props;
  const { anniversaries = [], report = {} } = result;

  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  if (error || loading || Object.keys(report).length === 0) {
    return <></>;
  }

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div>
        <Button onClick={() => toggleVisibility()} title={visible ? 'Esconder' : 'Mostrar'}>
          { visible ? (
            <VisibilityOffIcon />
          ) : (
            <VisibilityIcon />
          )}
        </Button>
      </div>
      <Grid container spacing={1} className={classes.container}>
        {
          report !== {} && (
          <BalanceReport data={report} visible={visible} />
          )
        }
      </Grid>

      <Grid container spacing={1} className={classes.container}>
        <Typography className={classes.label}>
          Saques
        </Typography>

        {
            anniversaries !== []
            && anniversaries.map(
              (anniversary) => <BalanceAnniversary data={anniversary} visible={visible} />,
            )
         }
      </Grid>
    </>
  );
}

export const Result = PropTypes.shape({
  anniversaries: PropTypes.arrayOf(Anniversary),
  report: PropTypes.arrayOf(Report),
});

BalanceResultContainer.propTypes = {
  result: PropTypes.objectOf(Result.propTypes).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default BalanceResultContainer;
