import { IMaskMixin } from 'react-imask';
import { Input } from '@mui/material';
import React from 'react';

/* eslint-disable react/jsx-props-no-spreading */
const CPFInput = IMaskMixin(({ inputRef, ...props }) => (
  <Input
    {...props}
    inputRef={inputRef}
  />
));

export default CPFInput;
