import React from 'react';
import { Controller } from 'react-hook-form';

import {
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import { banks } from '../../utils/banks';

/* eslint-disable react/prop-types, react/jsx-props-no-spreading */
export default function BankForm(props) {
  const { control, errors, register } = props;

  register('bank', {
    onChange: (e) => {
      console.log('CHANGE BANK', e.target.value);
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Dados bancários
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Para podermos transferir seu adiantamento, precisamos dos seus dados bancários
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Preste bastante atenção ao preenchê-los!
          O preenchimento errado impede a conclusão da operação.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.bank}>
          <InputLabel id="bankLabel">Banco</InputLabel>
          <Controller
            name="bank"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => {
              console.log('BANK VALUE', value);
              return (
                <Select
                  required
                  labelId="bankLabel"
                  label="Banco"
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                  fullWidth
                >
                  { banks.map((b) => <MenuItem key={b.code} value={b.code}>{b.label}</MenuItem>) }
                </Select>
              );
            }}
          />
          {errors.bank && (
            <FormHelperText>Banco é obrigatório</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="agency"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Agência"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.agency}
              helperText={errors.agency && 'Agência é obrigatório'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 4 }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="accountNumber"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="N Conta (SEM DÍGITO)"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.accountNumber}
              helperText={errors.accountNumber && 'Número da conta é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="accountNumberDigit"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="DÍGITO da Conta"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.accountNumberDigit}
              helperText={errors.accountNumberDigit && 'Dígito da conta é obrigatório'}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 1 }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.accountType}>
          <InputLabel id="accountType">Tipo de conta</InputLabel>
          <Controller
            name="accountType"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                required
                labelId="accountType"
                label="Tipo de conta"
                variant="outlined"
                onChange={onChange}
                value={value}
                fullWidth
              >
                <MenuItem value="CHECKING_ACCOUNT">Conta corrente</MenuItem>
                <MenuItem value="SAVINGS_ACCOUNT">Poupança</MenuItem>
              </Select>
            )}
          />
          {errors.accountType && (
            <FormHelperText>Tipo de conta é obrigatório</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}
/* eslint-enable react/prop-types, react/jsx-props-no-spreading */
