import {
  Switch,
  Route,
} from 'react-router-dom';
import { Button, Grid, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import BalancePage from '../pages/balance';
import TransferPage from '../pages/transfer';
import LoginPage from '../pages/login';
import SimulationPage from '../pages/simulation';

import history from '../history';
import CustomersPage from '../pages/customer';
import ManualSimulationPage from '../pages/simulation/manual';

const useStyles = makeStyles(() => ({
  header: {
    margin: '20px auto',
    paddingBottom: '5px',
    verticalAlign: 'middle',
    lineHeight: '55px',
    background: '#f7f7f7',
    boxShadow: '2px 2px 10px rgba(0,0,0,.1)',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  headerContainer: {
    width: '100%',
    margin: '20px auto',
    maxWidth: '1200px',
  },
  container: {
    maxWidth: '1300px',
    width: '100%',
    margin: '55px auto',
    display: 'flex',
  },
  link: {
    padding: '0px 8px',
    marginRight: '10px!important',
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'inline-block',
    decoration: 'none',
    '&:hover': {
      background: 'rgba(204,204,204,0.7)',
    },
  },
}));

export default function Routes() {
  const classes = useStyles();
  const url = `${process.env.PUBLIC_URL}/logo.png`;

  const home = () => {
    history.push('/');
  };

  const logo = () => (
    <Button onClick={() => home()}>
      <img alt="" src={url} width="200px" />
    </Button>
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerContainer}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={2}>{ logo() }</Grid>
            <Grid item xs={7} />
            <Grid item xs={3} alignContent="right">
              <Link className={classes.link} href="/"> Inicio </Link>
              <Link className={classes.link} href="/simular"> Simular </Link>
              <Link className={classes.link} href="/simular/manual"> Manual </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <div>
        <div className={classes.container}>
          <Switch>
            <Route exact path="/">
              <CustomersPage />
            </Route>

            <Route exact path="/simular">
              <SimulationPage />
            </Route>

            <Route exact path="/simular/manual">
              <ManualSimulationPage />
            </Route>

            <Route exact path="/balance">
              <BalancePage />
            </Route>

            <Route path="/transfer">
              <TransferPage />
            </Route>

            <Route path="/list">
              <CustomersPage />
            </Route>

            <Route path="/login">
              <LoginPage />
            </Route>

          </Switch>
        </div>
      </div>
    </>
  );
}
