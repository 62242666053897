import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid, Paper, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';
import history from '../../history';

const useStyles = makeStyles(() => ({
  label: {
    margin: '05px 0px',
    fontSize: '18px',
    fontWeight: 'bolder',
  },
  item: {
    margin: '25px 0px 0',
  },
  subItem: {
    margin: '5px 0px',
  },
  transferContainer: {
    margin: '15px',
  },
  btnTransfer: {
    margin: '0 15px',
  },
  small: {
    fontSize: '16px',
  },
}));

function BalanceReport(props) {
  const { data, visible } = props;

  const classes = useStyles();

  const requestTransfer = () => {
    history.push('/transfer', {
      amount: 12,
    });
  };

  return (
    <>
      <Paper elevation={0}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Resultado
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <Typography>
              Disponível para saque:
            </Typography>
            <NumberFormat value={data.transfer} displayType="text" thousandSeparator="." fixedDecimalScale decimalScale={2} decimalSeparator="," prefix=" R$ " />
          </Grid>
          <Grid item xs={12} className={classes.subItem}>
            {visible && (
            <div className={classes.small}>
              <Grid container={12}>
                <Grid item xs={4} className={classes.item}>
                  <Typography>
                    Total:
                  </Typography>
                  <NumberFormat value={data.total} displayType="text" thousandSeparator="." fixedDecimalScale decimalScale={2} decimalSeparator="," prefix=" R$ " />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                  <Typography>
                    Taxas:
                  </Typography>
                  <NumberFormat value={data.fee} displayType="text" thousandSeparator="." fixedDecimalScale decimalScale={2} decimalSeparator="," prefix=" R$ " />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                  <Typography>
                    IOF:
                  </Typography>
                  <NumberFormat value={data.iof} displayType="text" thousandSeparator="." fixedDecimalScale decimalScale={2} decimalSeparator="," prefix=" R$ " />
                </Grid>
              </Grid>
            </div>
            )}
          </Grid>
          <Grid item xs={12} className={classes.subItem}>
            <div className={classes.transferContainer}>
              <Button className={classes.btnTransfer} color="secondary" variant="contained" onClick={() => requestTransfer()}>
                Sacar
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export const Report = PropTypes.shape({
  transfer: PropTypes.number,
  total: PropTypes.number,
  fee: PropTypes.number,
  iof: PropTypes.number,
});

BalanceReport.propTypes = {
  data: PropTypes.objectOf(Report.propTypes).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default BalanceReport;
