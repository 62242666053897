import Base from './base';

export default class Service extends Base {
  getBalance(cpf, success, fail) {
    return this.post('/balance', {
      cpf,
    }, success, fail);
  }

  listCustomers(filter, success, fail) {
    return this.get(`/customers?${this.generateFilter(filter)}`, success, fail);
  }

  updateCustomer(id, customerFormData, success, fail) {
    return this.put(`/customers/${id}`, customerFormData, success, fail);
  }

  createContract(id, success, fail) {
    return this.post('/contracts', {
      id,
    }, success, fail);
  }

  cancelContract(id, success, fail) {
    return this.delete('/contracts', success, fail);
  }

  simulation(cpf, installment, success, fail) {
    return this.post('/simulation', {
      cpf,
      desired_installments: installment,
    }, success, fail);
  }

  manualSimulation(birthday, balance, installment, success, fail) {
    const today = new Date();
    const dates = birthday.split('/');
    const currentMonth = today.getMonth() + 1;
    let year = today.getFullYear();

    if (dates[1] < currentMonth) {
      year += 1;
    } else if (dates[1] === currentMonth) {
      if (dates[0] <= today.getDate()) {
        year += 1;
      }
    }

    return this.post('/simulation/manual', {
      balance,
      birthday: `${birthday}/${year}`,
      desired_installments: installment,
    }, success, fail);
  }
}
