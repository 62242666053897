import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  center: {
    display: 'block',
    width: '100%',
    maxWidth: '200px',
    margin: '0 auto',
    textAlign: 'center',
    padding: '25px',
  },
}));

function Center(prop) {
  const { children } = prop;
  const classes = useStyles();

  return (
    <div className={classes.center}>
      {children}
    </div>
  );
}

Center.propTypes = {
};

export default Center;
