import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, CircularProgress, Grid, Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmModal from '../../components/ConfirmModal';
import EditModal from '../../components/EditModal';
import Service from '../../api/service';
import Center from '../../components/Center';
import ErrorModal from '../../components/ErrorModal';
import dateConverter from '../../utils/date';

const useStyles = makeStyles(() => ({
  item: {
    marginTop: '25px',
    marginBottom: '5px',
    lineHeight: '70px',
    padding: '15px',
    fontSize: '15px',
    verticalAlign: 'middle',
    background: '#fff',
    borderRadius: '6px',
  },
  btn: {
    margin: '9px!important',
    lineHeight: '16px!important',
    fontWeight: 'bolder!important',
    fontSize: '12px!important',
    letterSpacing: '2px!important',
  },
  name: {
    paddingTop: '25px',
    paddingRight: '20px',
    lineHeight: '20px',
  },
  code: {
    fontWeight: 'bolder',
    fontSize: '14px',
    fontFamily: 'monospace',
  },
  data: {
    fontSize: '14px',
  },
  docs: {
    verticalAlign: 'middle',
    display: 'flex',
    flexDirection: 'column!important',
    lineHeight: '16px',
    marginTop: '-10px!important',
  },
}));

/* eslint-disable no-console, react/prop-types, no-nested-ternary,
 react/jsx-props-no-spreading, camelcase */
function CustomerItem(props) {
  const service = new Service();
  const { customer, resetList } = props;

  const [open, setOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const {
    id, name, cpf, phone, balance, status, contract, code, errors, inserted_at,
  } = customer;

  const data = dateConverter(inserted_at);

  const cleanCode = code.split('-')[0].toUpperCase();

  const contractUrl = contract ? contract.contract_url : '#';

  const fixedBalance = balance ? balance.split('.')[1].length > 2 ? balance.slice(0, -2) / 1.0 : balance : 0;

  const generateContract = () => {
    setLoading(true);
    service.createContract(id, (response) => {
      setLoading(false);
      resetList();
      console.log('Response', response);
    }, (err) => {
      setLoading(false);
      console.log('Err', err);
    });
  };

  const updateCustomer = (customerFormData) => {
    setLoading(true);
    service.updateCustomer(id, customerFormData, (response) => {
      setLoading(false);
      resetList();
      console.log('Response', response);
    }, (err) => {
      setLoading(false);
      console.log('Err', err);
    });
  };

  const showError = () => {
    setModalErrorOpen(true);
  };

  const requestTransfer = () => {
    console.log(`AESD${id}`);
  };

  const requestCancel = () => {
    console.log(`AESD${id}`);
  };

  const openModal = () => {
    setOpen(true);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const modalErrorAttrs = {
    title: 'Erro!',
    description: errors && errors.length > 0 ? errors.map((er) => er.error).join('\n') : '',
  };

  let modalAttrs;
  if (status === 'PROSPECT') {
    modalAttrs = {
      title: 'Gerar contrato?',
      description: `Deseja gerar o contrato para o ${name}?`,
      onConfirm: () => generateContract(),
    };
  } else if (status === 'AWAITING_SIGNATURE') {
    modalAttrs = {
      title: 'Cancelar contrato?',
      description: `Deseja realizar o CANCELAMENTO para o ${name}?`,
      onConfirm: () => requestCancel(),
    };
  } else {
    modalAttrs = {
      title: 'Realizar transferencia?',
      description: `Deseja realizar a TRANSFERENCIA para o ${name}? Confirme o saldo antes!`,
      onConfirm: () => requestTransfer(),
    };
  }

  let actionButton;
  if (status === 'PROSPECT') {
    actionButton = (
      <>
        <Button className={classes.btn} color="primary" variant="contained" size="small" onClick={openModal}>
          Gerar contrato
        </Button>

        {
          errors && errors.length > 0 && (
            <Button className={classes.btn} color="error" variant="contained" size="small" onClick={showError}>
              Erro!
            </Button>
          )
        }
      </>
    );
  } else if (status === 'AWAITING_SIGNATURE') {
    actionButton = (
      <Link href={contractUrl} target="_blank" rel="noreferrer">Contrato</Link>
    );
  }

  const editAttrs = {
    title: 'Editar usuário',
    customer,
    updateCustomer,
  };
  const editButton = (
    <Button onClick={() => openEdit()} title="Editar">
      <EditIcon />
    </Button>
  );
  // else {
  //   actionButton = (
  //     <Button
  //       color="secondary"
  //       variant="contained"
  //       size="small"
  //       onClick={openModal}
  //     >
  //       Transferir
  //     </Button>
  //   );
  // }

  return (
    <>

      <ConfirmModal {...modalAttrs} isOpen={open} setOpen={setOpen} />
      <EditModal {...editAttrs} isEditing={edit} setEdit={setEdit} />
      <ErrorModal {...modalErrorAttrs} isOpen={modalErrorOpen} setOpen={setModalErrorOpen} />

      <Grid container className={classes.item}>
        <Grid item xs className={classes.code}>
          {cleanCode}
          {editButton}
        </Grid>
        <Grid item xs className={classes.data}>{data}</Grid>
        <Grid item xs className={classes.name}>{name}</Grid>
        <Grid item xs>{cpf}</Grid>
        <Grid item xs>{phone}</Grid>
        <Grid item xs>
          <NumberFormat
            value={fixedBalance}
            displayType="text"
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator="."
            decimalSeparator=","
            prefix=" R$ "
          />
        </Grid>
        <Grid item xs>
          {loading ? (
            <Center>
              <CircularProgress />
            </Center>
          ) : actionButton}
        </Grid>
      </Grid>
    </>
  );
}

export const Contract = PropTypes.shape({
  id: PropTypes.number.isRequired,
  contract_url: PropTypes.string.isRequired,
});

export const Customer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  inserted_at: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  selfie_image: PropTypes.string.isRequired,
  document_image: PropTypes.string.isRequired,
  document_image_back: PropTypes.string.isRequired,
  address_image: PropTypes.string.isRequired,
  contract: PropTypes.objectOf(Contract),
});

CustomerItem.propTypes = {
  customer: PropTypes.objectOf(Customer).isRequired,
  resetList: PropTypes.func.isRequired,
};

export default CustomerItem;

/* eslint-enable no-console, react/prop-types, no-nested-ternary, camelcase */
