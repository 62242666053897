import React, { useState } from 'react';

import {
  Grid,
  Typography,
  TextField,
} from '@mui/material';

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { Controller } from 'react-hook-form';

import { v4 as uuid } from 'uuid';
import Dropzone from '../common/Dropzone';
import { storage } from '../../firebase';

import patterns from '../../utils/patterns';
import validations from '../../utils/validations';
import InputMask from '../common/InputMask';
import { validateImage } from '../../utils/image';

/* eslint-disable react/prop-types */
export default function PersonalDataForm(props) {
  const {
    previewSelfie,
    setPreviewSelfie,

    previewDocument,
    setPreviewDocument,

    previewDocumentBack,
    setPreviewDocumentBack,

    handleChangeImage,
    setError,
    control,
    errors,
  } = props;

  const env = 'production';
  const [files, setFiles] = useState({});
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [progressDocument, setProgressDocument] = useState(0);

  const [loadingDocumentBack, setLoadingDocumentBack] = useState(false);
  const [progressDocumentBack, setProgressDocumentBack] = useState(0);

  const [loadingSelfie, setLoadingSelfie] = useState(false);
  const [progressSelfie, setProgressSelfie] = useState(0);

  const imagesFn = {
    documentImage: {
      setProgress: setProgressDocument,
      setLoading: setLoadingDocument,
      setPreview: setPreviewDocument,
    },
    documentImageBack: {
      setProgress: setProgressDocumentBack,
      setLoading: setLoadingDocumentBack,
      setPreview: setPreviewDocumentBack,
    },
    selfieImage: {
      setProgress: setProgressSelfie,
      setLoading: setLoadingSelfie,
      setPreview: setPreviewSelfie,
    },
  };

  const saveImage = (name, fileUrl) => {
    setLoadingDocument(false);
    setLoadingDocumentBack(false);
    setLoadingSelfie(false);

    handleChangeImage(name, fileUrl);
  };

  const imageCheckCallbackError = (name) => {
    setLoadingDocument(false);
    setLoadingDocumentBack(false);
    setLoadingSelfie(false);
    setError(name, {
      type: 'manual',
      message: 'Imagem enviada inválida',
    });
  };

  const uploadImage = (name, file) => {
    imagesFn[name].setLoading(true);

    const upload = () => {
      const fileName = `${uuid()}-${file.name}`;
      const imageRef = ref(storage, `images/${env}/${name}/${fileName}`);

      const uploadTask = uploadBytesResumable(imageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const uploadProgress = (snapshot.bytesTransferred / (snapshot.totalBytes || 1)) * 100;
          imagesFn[name].setProgress(uploadProgress);
        },
        () => {
          imagesFn[name].setLoading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            saveImage(name, downloadURL);
          });
        },
      );
    };

    validateImage(name, file, upload, imageCheckCallbackError);
  };

  const onDrop = (name, acceptedFiles) => {
    let file = acceptedFiles[0];
    file = Object.assign(file, { preview: URL.createObjectURL(file) });
    const newFiles = { ...files, [name]: file };

    imagesFn[name].setProgress(0);
    imagesFn[name].setPreview(URL.createObjectURL(file));

    setFiles(newFiles);
    uploadImage(name, file);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Dados pessoais
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Inicialmente, precisamos dos seus dados pessoais.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="fullName"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            validate: validations.fullName,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Nome completo"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.fullName}
              helperText={errors.fullName && 'Nome completo é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: patterns.email,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="E-mail"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.email}
              helperText={errors.email && 'E-mail é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: patterns.phone,
          }}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask="(00) 00000-0000"
              value={value}
              onChange={onChange}
              required
              label="Celular"
              variant="outlined"
              error={!!errors.phone}
              helperText={errors.phone && 'Celular é obrigatório'}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="cpf"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask="000.000.000-00"
              value={value}
              onChange={onChange}
              required
              label="CPF"
              variant="outlined"
              error={!!errors.cpf}
              helperText={errors.cpf && 'CPF é obrigatório'}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="birthDate"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask="00/00/0000"
              value={value}
              onChange={onChange}
              required
              label="Data de nascimento"
              variant="outlined"
              error={!!errors.birthDate}
              helperText={errors.birthDate && 'Data de nascimento é obrigatório'}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="motherName"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Nome completo da mãe"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.motherName}
              helperText={errors.motherName && 'Nome completo da mãe é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="job"
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              required
              label="Profissão"
              variant="outlined"
              onChange={onChange}
              value={value}
              error={!!errors.job}
              helperText={errors.job && 'Profissão é obrigatório'}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Documento de identificação com foto*
        </Typography>
        <Typography variant="body2" gutterBottom>
          Frente e Verso no caso de RG.
          Documento aberto, se for CNH.
        </Typography>

        <Dropzone
          title="Documento de identificação"
          loading={loadingDocument}
          progress={progressDocument}
          preview={previewDocument}
          handleDrop={(acceptedFiles) => onDrop('documentImage', acceptedFiles)}
          error={errors.documentImage}
        />

        <br />
        <Dropzone
          title="Verso do Documento de identificação (caso tenha)"
          loading={loadingDocumentBack}
          progress={progressDocumentBack}
          preview={previewDocumentBack}
          handleDrop={(acceptedFiles) => onDrop('documentImageBack', acceptedFiles)}
          error={errors.documentImageBack}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Selfie com o documento de identificação*
        </Typography>
        <Typography variant="body2" gutterBottom>
          Com o mesmo documento anterior.
          Se estiver na dúvida em como fazer, basta seguir o exemplo do link a seguir:
          <a href="https://urbe.me/lab/wp-content/uploads/2019/02/SELFIE.png" target="_blank" rel="noreferrer">
            Exemplo de selfie
          </a>
        </Typography>

        <Dropzone
          title="Selfie"
          loading={loadingSelfie}
          progress={progressSelfie}
          preview={previewSelfie}
          handleDrop={(acceptedFiles) => onDrop('selfieImage', acceptedFiles)}
          error={errors.selfieImage}
        />
      </Grid>
    </Grid>
  );
}
/* eslint-enable react/prop-types */
