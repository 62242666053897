export default class Store {
  static getToken() {
    return localStorage.getItem('token');
  }

  static setToken(token) {
    localStorage.setItem('token', token);
  }

  static clear() {
    localStorage.clear();
  }
}
