import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@mui/material/styles';
import { Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import customTheme from './utils/theme';
import history from './history';
import Routes from './routes';

ReactDOM.render(
  <ThemeProvider theme={customTheme}>
    <Router history={history}>
      <Routes />
    </Router>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
