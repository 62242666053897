import Store from '../store';

/*  eslint-disable class-methods-use-this */
class Base {
  constructor() {
    this.BASE_URL = window.location.hostname === 'localhost' ? 'http://localhost:1234' : 'https://api.banckero.com.br';
  }

  generateFilter(filter) {
    return Object.entries(filter).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
  }

  validateAuth(res, fail) {
    console.log('RES', res);
    if (res.status === 403) {
      fail('Conexão negada, faça login novamente');
      alert('Conexão negada, faça login novamente');
      window.location.href = '/login';
    }
  }

  validate(res, json, success, fail) {
    if (res.status < 300 && typeof success === 'function') {
      success(json);
    } else if (typeof fail === 'function') {
      fail(json);
    }
    return null;
  }

  post(url, data, success, fail) {
    return fetch(this.BASE_URL + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: Store.getToken(),
      },
    }).then((res) => {
      this.validateAuth(res, fail);
      res.json().then((json) => {
        this.validate(res, json, success, fail);
      });
      return res;
    }).catch((err) => err);
  }

  put(url, data, success, fail) {
    return fetch(this.BASE_URL + url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: Store.getToken(),
      },
    }).then((res) => {
      res.json().then((json) => {
        this.validate(res, json, success, fail);
      });
      return res;
    }).catch((err) => err);
  }

  get(url, success, fail) {
    return fetch(this.BASE_URL + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Store.getToken(),
      },
    }).then((res) => {
      this.validateAuth(res, fail);
      res.json().then((json) => {
        this.validate(res, json, success, fail);
      });
      return res;
    }).catch((err) => err);
  }

  delete(url, success, fail) {
    return fetch(this.BASE_URL + url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Store.getToken(),
      },
    }).then((res) => {
      this.validateAuth(res, fail);
      res.json().then((json) => {
        this.validate(res, json, success, fail);
      });
      return res;
    }).catch((err) => err);
  }
}

export default Base;
/*  eslint-enable class-methods-use-this */
