import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  Grid, InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import Alert from '@mui/material/Alert';
import CustomerItem from './CustomerItem';
import Center from '../../components/Center';
import Service from '../../api/service';

const useStyles = makeStyles(() => ({
  list: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: 0,
  },
  listHeader: {
    background: '#e7e7e7',
    padding: '6px',
    color: '#9b9a9a',
    fontSize: '14px',
    fontWeight: 'bolder',
  },
  container: {
    padding: '15px',
  },
}));

export default function CustomersPage() {
  const service = new Service();
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({});

  const [filter, setFilter] = useState({
    status: 'PROSPECT',
  });

  const resetList = () => {
    window.location.reload();
  };

  const handleStatusChange = (event) => {
    setFilter({ ...filter, status: event.target.value });
  };

  useEffect(() => {
    setLoading(true);
    service.listCustomers(filter, (response) => {
      setResult(response);
      setLoading(false);
      setError(false);
    }, () => {
      setLoading(false);
      setError(true);
    });
  }, [filter]);

  return (
    <>
      <Grid container>
        <Grid container justifyContent="flex-end">
          <FormControl sx={{ m: 0, minWidth: 200 }}>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              id="status-select"
              labelId="status-select-label"
              label="Status"
              value={filter.status}
              onChange={handleStatusChange}
              displayEmpty
            >
              <MenuItem value="PROSPECT">Interessados</MenuItem>
              <MenuItem value="AWAITING_SIGNATURE">Aguardando assinatura</MenuItem>
              <MenuItem value="SIGNED">Assinados</MenuItem>
              <MenuItem value="CANCELED">Cancelados</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {
          error && (
            <Alert severity="error">{error.message}</Alert>
          )
        }
        <ul className={classes.list}>
          <Grid container className={classes.listHeader}>
            <Grid item xs><small style={{ paddingLeft: '20px', fontWeight: 'bold' }}>Cod.</small></Grid>
            <Grid item xs><small>Data</small></Grid>
            <Grid item xs><small>Nome</small></Grid>
            <Grid item xs><small>CPF</small></Grid>
            <Grid item xs><small>Telefone</small></Grid>
            <Grid item xs><small>Saldo</small></Grid>
            <Grid item xs><small>Ações</small></Grid>
          </Grid>
          {loading ? (
            <Center>
              <CircularProgress />
            </Center>
          ) : (
            <div className={classes.container}>
              {
                (result.results || []).map((c) => (
                  <CustomerItem key={c.id} customer={c} resetList={resetList} />
                ))
              }
            </div>
          )}
        </ul>
      </Grid>
    </>
  );
}
