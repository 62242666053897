import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid, Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import BalanceReport from './BalanceReport';
import BalanceInstallment, { Installment } from './BalanceInstallment';

const useStyles = makeStyles(() => ({
  container: {
    padding: '15px',
    margin: '10px',
    fontSize: '18px',
    fontWeight: 'bolder',
    border: '1px solid #ccc',
    borderRadius: 3,
  },
  label: {
    margin: '05px 0px',
    fontSize: '18px',
    fontWeight: 'bolder',
  },
}));

function BalanceResultContainer(props) {
  const { result, error, loading } = props;
  const { installments = [] } = result;

  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  if (error || loading || Object.keys(result).length === 0) {
    return <></>;
  }

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button onClick={() => toggleVisibility()} title={visible ? 'Esconder' : 'Mostrar'}>
            {visible ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12}>
          {
          result !== {} && (
            <BalanceReport data={result} visible={visible} />
          )
        }
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.container}>
        <Typography className={classes.label}>
          Saques
        </Typography>

        {
          installments !== []
          && installments.map(
            (installment) => <BalanceInstallment data={installment} visible={visible} />,
          )
        }
      </Grid>
    </>
  );
}

export const Result = PropTypes.shape({
  installments: PropTypes.arrayOf(Installment).isRequired,
  fees: PropTypes.number.isRequired,
  iof: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  transferable: PropTypes.number.isRequired,
});

BalanceResultContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  result: PropTypes.objectOf(Result).isRequired,
};

export default BalanceResultContainer;
