import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    background: '#fff',
    primary: {
      main: '#29368e',
      contrastText: 'white',
    },
    secondary: {
      main: '#029d05',
    },
    error: {
      main: '#9d0202',
    },
    gray: {
      main: '#ccc',
    },
  },
  overrides: {
    PrivateNotchedOutline: {
      legend: {
        width: '265px !important',
      },
    },
  },
});

export default Theme;
