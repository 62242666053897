const imageCheckByUrl = (name, url, callbackLoad, callbackError) => {
  const imageCheck = new Image();
  imageCheck.src = url;
  imageCheck.style.display = 'none';

  imageCheck.onload = () => callbackLoad();
  imageCheck.onerror = () => callbackError(name);

  document.body.appendChild(imageCheck);
};

const validateImage = (name, file, callback, imageCheckCallbackError) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const base64String = event.target.result;

    imageCheckByUrl(name, base64String, callback, imageCheckCallbackError);
  };
  reader.readAsDataURL(file);
};

export { imageCheckByUrl, validateImage };
