import React, { useState } from 'react';
import {
  Button, CircularProgress, FormControl, FormHelperText, InputLabel,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import Alert from '@mui/material/Alert';

import CPFInput from '../../components/CPFInput';

import Service from '../../api/service';
import BalanceResultContainer from './BalanceResultContainer';

const useStyles = makeStyles(() => ({
  infos: {
    margin: '25px 15px',
  },
  container: {
    maxWidth: '600px',
    width: '100%',
    margin: '15px auto',
  },
  center: {
    display: 'block', width: '100%', maxWidth: '200px', margin: '0 auto', textAlign: 'center',
  },
}));

function Center(prop) {
  const { children } = prop;
  const classes = useStyles();

  return (
    <div className={classes.center}>
      {children}
    </div>
  );
}

Center.propTypes = {
};

export default function BalancePage() {
  const classes = useStyles();

  const [cpf, setCpf] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  const service = new Service();

  const onClickGetBalance = () => {
    setLoading(true);
    setError(false);
    setResult({});

    service.getBalance(cpf, (ok) => {
      setResult(ok);
      setError(false);
      setLoading(false);
    }, (fail) => {
      setError(fail);
      setLoading(false);
    });
  };

  return (
    <>
      <FormControl fullWidth className={classes.container}>
        <InputLabel htmlFor="cpf-input">CPF</InputLabel>
        <CPFInput
          mask="000.000.000-00"
          id="cpf-input"
          value={cpf}
          onAccept={(value) => setCpf(value)}
          aria-describedby="cpf-helper-text"
        />
        <FormHelperText id="cpf-helper-text">Insira o CPF para simular</FormHelperText>

        <br />
        <br />

        <Button color="primary" variant="contained" onClick={() => onClickGetBalance()}>
          Ver Saldo
        </Button>
        <div className={classes.infos}>
          {
            loading && (
              <Center>
                <CircularProgress />
              </Center>
            )
          }

          {
            error && (
              <Alert severity="error">{error.message}</Alert>
            )
          }
        </div>

        <BalanceResultContainer result={result} error={!!error} loading={loading} />
      </FormControl>
    </>
  );
}

/* <MaskedStyledInput
    mask='000.000.000-00'
    unmask={true}
    onAccept={
        (value, mask) => console.log(value)
    }
    placeholder='Insira o CPF para simular'
/> */
