import React, { useState } from 'react';
import {
  Button, Grid, TextField, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Store from '../store';
import history from '../history';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '800px',
    padding: '15px',
    margin: '10px auto',
    fontSize: '18px',
    fontWeight: 'bolder',
    border: '1px solid #ccc',
    borderRadius: 3,
  },
}));

function LoginPage() {
  const classes = useStyles();

  const [token, setToken] = useState('');

  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  const saveToken = () => {
    Store.setToken(token);

    history.replace('/', {});
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="center" className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Login
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="token-input"
            value={token}
            onChange={handleTokenChange}
            fullWidth
            label="Token"
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={() => saveToken()} color="primary" variant="contained" fullWidth>
            Salvar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default LoginPage;
