const banks = [
  { code: '000', label: 'Selecionar' },
  { code: '001', label: 'Banco do Brasil' },
  { code: '033', label: 'Santander' },
  { code: '077', label: 'Banco Inter' },
  { code: '104', label: 'Caixa Econômica' },
  { code: '208', label: 'BTG Pactual' },
  { code: '212', label: 'Original' },
  { code: '237', label: 'Bradesco' },
  { code: '260', label: 'Nubank' },
  { code: '336', label: 'C6 Bank' },
  { code: '341', label: 'Itaú Unibanco' },
  { code: '380', label: 'Picpay' },
  { code: '422', label: 'Safra' },
  { code: '623', label: 'Banco PAN' },
  { code: '748', label: 'Sicredi' },
  { code: '756', label: 'Bancoob' },
  { code: '756', label: 'Sicoob' },
];

const bankByCode = (code) => {
  let chosenBank = banks[0];
  for (let i = 0; i < banks.length; i += 1) {
    const bank = banks[i];
    if (bank.code === code) {
      chosenBank = bank;
      break;
    }
  }
  return chosenBank;
};

export {
  banks, bankByCode,
};
