/* eslint-disable import/no-anonymous-default-export */
/**
 * Validates a cpf
 * @param {string} input Inputted cpf number
 */
const cpf = (input) => {
  const number = input.replace(/[^0-9]/g, '');
  let sum = 0;
  let rest;

  if (
    number.length !== 11
        || !Array.from(number).filter((e) => e !== number[0]).length
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) sum += parseInt(number.substring(i - 1, i), 10) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(number.substring(9, 10), 10)) return false;

  sum = 0;
  for (let i = 1; i <= 10; i += 1) sum += parseInt(number.substring(i - 1, i), 10) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(number.substring(10, 11), 10)) return false;
  return true;
};

function formatCPF(txt) {
  const acpf = txt.replace(/[^\d]/g, '');
  return acpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

/**
 * Validates if name has surname
 * @param {string} input Inputted name
 * @return {boolean} if theres a space or not
 */
const fullName = (input) => input.trim().indexOf(' ') > -1;

export default { cpf, fullName, formatCPF };
