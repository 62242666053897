import React, { useState } from 'react';
import {
  Button, CircularProgress,
  FormControl, Grid, InputLabel, MenuItem, Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Service from '../../api/service';
import Center from '../../components/Center';
import BalanceResultContainer from './BalanceResultContainer';
import InputMask from '../../components/common/InputMask';

const useStyles = makeStyles(() => ({
  input: {
    margin: '15px 5px',
    width: '100%',
  },
  infos: {
    margin: '25px 15px',
  },
  container: {
    display: 'flex',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  },
}));

export default function ManualSimulationPage() {
  const classes = useStyles();

  const [balance, setBalance] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [installment, setInstallment] = useState(5);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  const installmentsRange = [1, 2, 3, 4, 5];
  const onInstallmentChange = (e) => {
    setInstallment(e.target.value);
  };

  const handleBalanceChange = (e) => {
    setBalance(e.target.value);
  };

  const handleBirthdayChange = (e) => {
    setBirthdate(e.target.value);
  };

  const service = new Service();

  const onClickGetBalance = () => {
    setLoading(true);
    setError(false);
    setResult({});

    service.manualSimulation(birthdate, balance, installment, (ok) => {
      setResult(ok);
      setError(false);
      setLoading(false);
    }, (fail) => {
      setError(fail);
      setLoading(false);
    });
  };

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputMask
              label="Saldo"
              id="balance-input"
              value={balance}
              onChange={handleBalanceChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputMask
                mask="00/00"
                value={birthdate}
                onChange={handleBirthdayChange}
                required
                label="Dia e mes de nascimento"
                variant="outlined"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="installment-select-label">Parcelas</InputLabel>
              <Select
                id="installment-select"
                labelId="installment-select-label"
                label="Parcelas"
                value={installment}
                onChange={onInstallmentChange}
                displayEmpty
              >
                {
                  installmentsRange.map((i) => (
                    <MenuItem key={i} value={i}>
                      {`${i} Parcelas`}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" variant="contained" onClick={() => onClickGetBalance()}>
              Ver Saldo
            </Button>
            <div className={classes.infos}>

              {
                loading && (
                  <Center>
                    <CircularProgress />
                  </Center>
                )
              }

              {
                error && (
                  <Alert severity="error">{error.message}</Alert>
                )
              }
            </div>

            <BalanceResultContainer result={result} error={!!error} loading={loading} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
