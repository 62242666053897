import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(() => ({
  label: {
    margin: '05px 0px',
    fontSize: '18px',
    fontWeight: 'bolder',
  },
  item: {
    margin: '10px 0px',
    padding: '10px',
    borderTop: '1px dashed #ccc',
  },
  small: {
    margin: '5px 0',
    fontSize: '14px',
    display: 'block',
  },
}));

function BalanceInstallment(props) {
  const { data, visible } = props;
  const classes = useStyles();

  const formatDate = (date) => {
    const s = date.split('-');
    return `${s[2]}/${s[1]}/${s[0]}`;
  };

  return (
    <>
      <Grid container spacing={2} className={classes.item}>
        <Grid item xs={9}>
          <Typography>
            Data:
          </Typography>
          <Typography>
            { formatDate(data.due_date) }
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.small}>
            <Typography>
              Total:
            </Typography>
            <NumberFormat value={data.total_amount} displayType="text" fixedDecimalScale decimalScale={2} thousandSeparator="." decimalSeparator="," prefix=" R$ " />
          </div>
        </Grid>
        <Grid item xs={3}>
          { visible && ('') }
        </Grid>
        <Grid item xs={3}>
          <Typography>
            {/* Disponível: */}
          </Typography>
          <NumberFormat value="" displayType="text" fixedDecimalScale decimalScale={2} thousandSeparator="." decimalSeparator="," prefix=" R$ " />
        </Grid>
      </Grid>
    </>
  );
}

export const Installment = PropTypes.shape({
  total_amount: PropTypes.number,
  tax_amount: PropTypes.number,
  due_date: PropTypes.string,
});

BalanceInstallment.propTypes = {
  data: PropTypes.arrayOf(Installment.propTypes).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default BalanceInstallment;
